// widgets.js
// import ticketFixture, { newTicket } from "./fixtures";
import { apiClient } from "../../../apiClient/apiClient";
import { SOLVED } from "../../../functions";

let timer;

export const COMMUNICATIONS_TICKETS = "communications/tickets";

// Actions
const LOAD = `${COMMUNICATIONS_TICKETS}/LOAD`;
const CREATE = `${COMMUNICATIONS_TICKETS}/CREATE`;
const UPDATE = `${COMMUNICATIONS_TICKETS}/UPDATE`;
const REMOVE = `${COMMUNICATIONS_TICKETS}/REMOVE`;
const FETCH = `${COMMUNICATIONS_TICKETS}/FETCH`;

// Messages reducer
const ADD_MESSAGE = `${COMMUNICATIONS_TICKETS}/ADD_MESSAGE`;
const FETCH_MESSAGES = `${COMMUNICATIONS_TICKETS}/FETCH_MESSAGES`;

// Reducer
export const ticketsReducer = (state = [], action = {}) => {
  switch (action.type) {
    // do reducer stuff
    case CREATE:
      return [action.payload, ...state];

    case UPDATE:
      return;

    case REMOVE:
      return;

    case FETCH:
      return action.payload;

    default:
      return state;
  }
};

/**
 * Reducer only for Messages in tickets.
 * @param {*} state
 * @param {*} action
 */
export const ticketsMessagesReducer = (state = [], action = {}) => {
  switch (action.type) {
    case ADD_MESSAGE:
      return [...state, action.payload];

    case FETCH_MESSAGES:
      return action.payload;

    default:
      return state;
  }
};

// Action Creators
export const loadTickets = () => ({ type: LOAD });

/**
 *
 * @param {*} tickets
 */
export const fetchTicketsAction = (tickets) => ({
  type: FETCH,
  payload: tickets,
});

/**
 *
 * @param {*} messages
 */
export const fetchTicketMessagesAction = (messages) => ({
  type: FETCH_MESSAGES,
  payload: messages || [],
});

/**
 *
 * @param {*} ticket
 */
export const createTicket = (ticket) => ({
  type: CREATE,
  payload: ticket,
});

/**
 *
 * @param {*} ticket
 */
export const updateTicket = (ticket) => ({
  type: UPDATE,
  ticket,
});

/**
 *
 * @param {*} ticket
 */
export const removeTicket = (ticket) => ({
  type: REMOVE,
  ticket,
});

/**
 *
 * @param {*} ticket
 */
export const addMessage = (ticket) => ({
  type: ADD_MESSAGE,
  payload: ticket,
});

/**
 *
 * @param {*} id
 */
export const getTicketById = async (id) => {
  const { data, status } = await apiClient().get(
    `${COMMUNICATIONS_TICKETS}/${id}`,
    {
      params: {
        sort: `["createdAt","DESC"]`,
      },
    }
  );

  return Object.freeze({
    data,
    status,
  });
};

const defaultOptions = {
  params: {
    sort: `["createdAt","DESC"]`,
  },
};

const filterSolvedTickets = (tickets) =>
  tickets.filter((ticket) => ticket.processingState !== SOLVED);

/**
 *
 * @param {*} options
 */
const fetchTicketsDESC = (options) =>
  apiClient().get(COMMUNICATIONS_TICKETS, options || defaultOptions);

/**
 * operations
 */
export const fetchTickets = () => {
  return async (dispatch) => {
    const { data } = await fetchTicketsDESC({
      params: {
        sort: `["updatedAt","DESC"]`,
      },
    });
    const dataWithoutArchivedTickets = filterSolvedTickets(data);
    dispatch(fetchTicketsAction(dataWithoutArchivedTickets));
  };
};

/**
 *
 * @param {*} id
 */
export const fetchTicketMessagesThunk = (id) => {
  return async (dispatch) => {
    const response = await apiClient().get(
      `${COMMUNICATIONS_TICKETS}/${id}/messages`,
      {
        params: {
          sort: `["createdAt","ASC"]`,
        },
      }
    );

    if (response && response.data) {
      dispatch(fetchTicketMessagesAction(response.data));
    }
  };
};

/**
 *
 * @param {*} estateId
 */
export function fetchTicketsByEstateId(estateId) {
  return async (dispatch) => {
    const response = await fetchTicketsDESC({
      params: {
        sort: `["updatedAt","DESC"]`,
        filter: { estateId },
      },
    });

    if (response && response.data) {
      const dataWithoutArchivedTickets = filterSolvedTickets(response.data);
      dispatch(fetchTicketsAction(dataWithoutArchivedTickets));
    }

    // TODO: recursion should not be part of the operation
    clearTimeout(timer);
    timer = setTimeout(() => {
      dispatch(fetchTicketsByEstateId(estateId));
    }, 15000);
  };
}
