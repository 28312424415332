import "@babel/polyfill";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import store, { history } from "./store";
import App from "./containers/App/App";
import { fetchCurrentUser } from "./modules/user/userActions";
import { saveState } from "./localStorage";
import { unregister } from "./registerServiceWorker";

// set german locale for moment
import "moment/locale/de";

const { sessionToken } = store.getState();

// persist current state
store.subscribe(() => {
  saveState({
    tickets: {
      readTicketsIds: store.getState().tickets.readTicketsIds,
    },
    estates: {
      currentEstate: store.getState().estates.currentEstate,
    },
    sessionToken: store.getState().sessionToken,
    propertyManagement: store.getState().propertyManagement,
  });
});

// initialize store only if the session token exists
if (!!sessionToken) {
  store.dispatch(fetchCurrentUser());
}

ReactDOM.render(
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <App />
    </ConnectedRouter>
  </Provider>,
  document.getElementById("root"),
);

// Disable service worker until a proper version update mechanism is implemented
// registerServiceWorker();
unregister();
