import { connect } from "react-redux";
import { compose, branch, renderComponent } from "recompose";

/**
 * Example usage:
 *
 * const enhance = compose(
 *  withCurrentUser(options)
 * );
 *
 * A redux helper to decorate a component with a currentUser object.
 */
export const withCurrentUser = (/** options if necessary */) => {
  const mapStateToProps = ({ currentUser }) => ({ currentUser });
  return connect(mapStateToProps);
};

/**
 *
 * @param {*} a
 * @param {*} b
 */
const compare = (a, b) => {
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  return Number(Boolean(nameA > nameB)) === 1 ? 1 : -1;
};

export const withServiceTypes = (compareConsumer = compare) => {
  const mapStateToProps = ({ estates }) => ({
    serviceTypes: estates.currentEstate.serviceTypes.sort(compareConsumer),
  });

  return connect(mapStateToProps);
};

/**
 *
 */
export const withTicketsList = (/** options if necessary */) => {
  const mapStateToProps = ({ tickets }) => ({ tickets: tickets.ticketsList });
  return connect(mapStateToProps);
};

/**
 * Example usage:
 *
 * const enhance = compose(
 *  withCurrentEstate(options)
 * );
 *
 * A redux helper to decorate a component with the currentEstate object.
 */
export const withCurrentEstate = (/** options if necessary */) => {
  const mapStateToProps = ({ estates }) => ({
    currentEstate: estates.currentEstate,
  });
  return connect(mapStateToProps);
};

/**
 * Example usage:
 *
 * const enhance = compose(
 *  withEstates(options)
 * );
 *
 * A redux helper to decorate a component with the estates by userId.
 */
export const withEstates = (/** options if necessary */) => {
  const mapStateToProps = ({ estates }) => ({ estates: estates.estates });
  return connect(mapStateToProps);
};

// https://github.com/acdlite/recompose/wiki/Recipes
/**
 * Example usage:
 *
 * const enhance = compose(
 *  anotherHoc,
 *  nonOptimalStates([
 *    { when: hasErrorCode, render: AuthError },
 *    { when: hasNoUsers, render: NoUsersMessage }
 *  ]
 * );
 *
 * @param {*} states
 */
export const nonOptimalStates = (states) =>
  compose(
    ...states.map((state) => branch(state.when, renderComponent(state.render)))
  );
